import * as React from "react";
import { graphql } from "gatsby";
import { useIntl } from "react-intl";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Navigation from "../components/navigation";
import Header from "../components/header";
import Content from "../components/content";
import Masonry from "../components/masonry";

const Index = ({ data }) => {
  const { formatMessage } = useIntl();
  const pages = data.pages.nodes;

  return (
    <Layout>
      <Seo />
      <Header
        title={formatMessage({ id: `title` })}
        intro={formatMessage({ id: `intro` })}
      />
      <Navigation pages={pages} isIndex />
      <Content>
        <Masonry pages={pages} />
      </Content>
    </Layout>
  );
};

export default Index;

export const query = graphql`
  query ($locale: String!) {
    pages: allMdx(
      filter: {
        fields: { locale: { eq: $locale } }
        frontmatter: { order: { ne: null } }
      }
      sort: { fields: [frontmatter___order], order: ASC }
    ) {
      nodes {
        id
        frontmatter {
          title
          permalink
          icon
          description
          images {
            childImageSharp {
              gatsbyImageData(width: 640, quality: 80)
            }
          }
        }
      }
    }
  }
`;
