import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { useIntl, FormattedMessage } from "react-intl";
import { GatsbyImage } from "gatsby-plugin-image";
import LocalizedLink from "../components/localized-link";

const query = graphql`
  query {
    earthBoundariesImage: file(
      absolutePath: { regex: "/earth-boundaries.jpg/" }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`;

const Masonry = ({ pages }) => {
  const { earthBoundariesImage } = useStaticQuery(query);
  const { formatMessage } = useIntl();

  return (
    <ul className="masonry">
      {pages.map(
        ({ id, frontmatter: { permalink, title, description, images } }) => (
          <li key={id}>
            <LocalizedLink to={`/${permalink}`}>
              <span className="sr-only">{title}</span>
            </LocalizedLink>
            <div>
              <h2
                aria-hidden="true"
                dangerouslySetInnerHTML={{ __html: title }}
              />
              <p>{description}</p>
            </div>
            <figure>
              <GatsbyImage
                image={images[0].childImageSharp.gatsbyImageData}
                alt={title}
              />
            </figure>
          </li>
        )
      )}
      <li>
        <div>
          <h2>
            <FormattedMessage id="earthBoundaries" />
          </h2>
          <p>
            <FormattedMessage id="availableSoon" />
          </p>
        </div>
        <figure>
          <GatsbyImage
            image={earthBoundariesImage.childImageSharp.gatsbyImageData}
            alt={formatMessage({ id: `earthBoundaries` })}
          />
        </figure>
      </li>
    </ul>
  );
};

export default Masonry;
